:root {
  --purple: #9b09c9;
  --black: #0f0000;
  --orange: #ff5500;
  --blue: #4eeaf6;
  --pink: #ec04c5;
  --fusia: #c82586;
  --white: #f7f7f8;
  --dark_blue: #0f0a34;
}

h1,
h2,
h3,
h4,
h5 {
  justify-content: center;
  text-align: 'center';
}
.App {
  text-align: center;
}

body {
  background-color: var(--white);
}

h1 {
  padding-top: '40px';
  padding-bottom: 20px;
  font-size: 30px;
  text-align: 'center';
}

p {
  text-indent: 50px;
}

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
  justify-content: space-around;
}
/*hero*/
.hero_img {
  max-width: 800px;
  padding: 150px 80px 0px 0px;
}

.header {
  /*background-image: url('assets/hero_1.jpg');*/
  background-position: center;
  display: flex;
  justify-content: center;
  height: 100vh;
  width: auto;
  background-size: cover;
  background-color: var(--dark_blue);
  background-repeat: no-repeat;
  /*opacity: 85%;*/
  margin: 50px 0px 0px 0px;
}

.header_title {
  display: block;
  text-align: center;
  margin: auto;
  padding: 20px;
  align-content: center;
  width: 100%;
  color: var(--white);

  border-radius: 5px;
}

.content_left {
  align-content: center;
  width: 50%;
  padding-left: 60px;
}
.content_right {
  width: 50%;
  justify-content: center;
}

.header_title_first {
  font-size: 20px;
  color: var(--fusia);
}
.header_title_second {
  font-size: 30px;
  color: purple;
}

.btn_contact {
  padding: 8px 60px;
  margin: 20px 0px 20px 0px;
  color: var(--white);
  background-color: var(--fusia);
  border-radius: 3px;
  border: 3px solid var(--fusia);
}

.btn_contact:hover {
  color: var(--fusia);
  background-color: var(--white);
  border: 3px solid var(--fusia);
}

.aboutme {
  background-color: var(--dark_blue);
  display: block;
  color: white;
  padding: 60px 180px;
  text-align: center;
}

.aboutme h1 {
  color: var(--fusia);
  font-size: 30px;
  text-decoration: underline var(--blue);
}

.aboutme_content {
  display: flex;
  padding: 0px 100px;
  justify-content: center;
  text-align: center;
}

.card {
  width: 20rem;
}

.card:hover {
  box-shadow: 5px 10px 20px 1px rgba(0, 0, 0, 0.253) !important;
}

.card-body {
  text-align: center;
  padding: 3rem 0 !important;
}

.card-text {
  font-size: 1rem;
  padding: 0.4rem 1.9rem;
}

.container-fluid .row {
  padding-top: 6rem;
}

.headerImg {
  height: 500px;
}

.myHeroTitle {
  padding-right: 30px;
}

/*navbar styling*/
/* change the background color */
.navbar {
  background-color: var(--dark_blue);
}

.nav1 {
  display: flex;
  justify-content: space-evenly;

  width: 100%;
}
.nav2 {
  display: flex;
  justify-content: space-evenly;

  width: 100%;
}

.nav-menu-items {
  width: 100%;
}

@media only screen and (max-width: 960px) {
  .nav-menu-items {
    width: 100%;
    margin-top: 40%;
    position: absolute;
    justify-content: right;
    text-align: center;
    background-color: var(--black);
  }
}

.nav-link {
  color: var(--white);
  font-size: 20px;
}

.navlink {
  color: var(--white);
}

a.navlink.nav-link.active {
  text-decoration: underline solid var(--fusia) 3px;
  text-underline-offset: 8px;
  background-color: var(--dark_blue);
  color: var(--white);
}
.navlink.nav-link:hover {
  text-decoration: underline solid var(--fusia) 3px;
  background-color: var(--dark_blue);
  color: var(--white);
}

.navlink.nav-link:focus {
  color: var(--fusia);
}

/* Make navigation link active when scrolled section

  /** active navigation **/
/*.nav-container.scroll a.active {
  color: var(--black);
  border-bottom: 2px solid #000;
}*/
/*Card*/

/*card footer icons*/

.card-footer {
  size: '2x';
  animation: 'spin';

  color: 'white';
  padding: '20px';
}

/*carousal modal*/

.modal-90w {
  width: 1200px;
  background-color: red;
}

.btnPlayIcons :active {
  border: 'none';
}

/* icons*/
.icon_menu {
  color: var(--fusia);
}

/* cards scroll*/

.horizontal-scroll-wrapper {
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  width: 80px;
  max-height: 500px;
  margin: 0;
  background: #abc;
  overflow-y: auto;
  overflow-x: hidden;
  transform: rotate(-90deg) translateY(-80px);
  transform-origin: right top;
}
.horizontal-scroll-wrapper > div {
  display: block;
  padding: 5px;
  background: #cab;
  transform: rotate(90deg);
  transform-origin: right top;
}

.squares {
  padding: 60px 0 0 0;
}

.squares > div {
  width: 60px;
  height: 60px;
  margin: 10px;
}

/*footer*/

.footer {
  background-color: var(--dark_blue);
  display: flex;
  justify-content: flex-end;
  padding: 15px;
}

.footer_icons {
  color: var(--fusia);
  padding: 15px;
}

.footer_name {
  color: var(--white);
  padding: 10px;
}

/* project */

.projects h1 {
  font-size: 30px;
  width: 100%;
  text-align: 'center';
  color: var(--dark_blue);
  text-decoration: underline var(--fusia);
}
.tech_list {
  text-decoration: none;
}
.projects {
  background-color: #f2f2f2;
  padding-bottom: 50px;
}
.project_cards_section {
  width: 100%;
  display: 'flex';
  justify-content: 'center';
  text-align: center;
  flex-direction: row;
}

.project_cards {
  width: 20rem;
  height: 480px;
  margin: 20px;
}

.skills_cards {
  /*box-shadow: 6px 5px 6px var(--fusia);*/
  width: 16rem;
  height: 400px;
}

.skills_cards_header {
  background-color: var(--dark_blue);
}

/*experience*/
.experience {
  width: 100%;
  height: 100%;
  padding: 50px;
}

.experience h1 {
  padding-top: '20px';
  text-align: center;
  color: var(--dark_blue);
  text-decoration: underline var(--fusia);
  font-size: 30px;
}

.experience_box {
  background-color: var(--dark_blue);
  width: 60%;
  min-height: 380px;
  border-radius: 20px;
  padding: 2%;

  /* offset-x | offset-y | blur-radius | color */
  box-shadow: 6px 4px 8px var(--fusia);
  color: var(--white);
  margin-bottom: 2%;
}

.experience_box_header {
  display:flex;


}
/*
@media only screen and (max-width:960px){
  .experience_box{
overflow-y: scroll;
  }
}
*/
h1 {
  font-size: larger;
}
p{
  font-size: large;
}

.experience_box > h1,
h2,
h3,
h4,
h5,
h6 {
  display: flex;
  justify-content: center;
}

.experience_box ul {
  padding-top: 20px;
}

.experience_box > ul > li {
  display: flex;
  text-align: left;
  padding: 12px;
  list-style-type: none;
}

.experience_section {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  margin: 40px 5px 40px 5px;
}
.experience_nav {
  display: flex;
  flex-direction: column;

  list-style: none;
}
.experience_nav > ul {
}

.experience_nav > li {
  margin: 15px;
  margin-right: 40px;
  padding: 10px;
}

.experience_nav > li:hover {
  border-left: 5px solid var(--dark_blue);
  cursor: pointer;
}

.exp_active {
  border-left: 5px solid #4eeaf6;
}

.exp_caret {
  color: #4eeaf6;
  padding-right: 10px;
}

/* education*/

.edu_section {
  background-color: #a5e6eb;
}

.edu_section h1 {
  padding-top: '20px';
  text-align: center;
  color: var(--dark_blue);
  text-decoration: underline var(--fusia);
  font-size: 30px;
}

.edu_card {
  width: 25rem;
  height: 430px;
  margin: 25px;
  border-radius: 20px;
  padding: 2%;

  /* offset-x | offset-y | blur-radius | color */
  /*  box-shadow: 6px 5px 6px var(--purple);*/
}

/*projects fontawesome icon spin*/
.slow_spin {
  -webkit-animation: fa-spin 6s infinite linear;
  animation: fa-spin 6s infinite linear;
}

/*skills*/
.skills {
  width: 100%;
  height: 100%;
  padding: 50px;
  background-color: #a5e6eb;
}

.skills h1 {
  padding-top: '20px';
  text-align: center;
  color: var(--dark_blue);
  text-decoration: underline var(--fusia);
  font-size: 30px;
}

.skills-columns {
  list-style-type: none;
  columns: 2;
  text-align: left;
}

/*quote*/

.quote {
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  padding: 60px 30px;
  align-items: center;
}

.quote img {
  width: 250px;
}
